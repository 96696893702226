<template>
  <div class="installationRelationsList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(installationRelationsList)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(installationRelationsList)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="parentNameLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span v-if="props.dataItem.isParent">{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            <router-link
              v-else
              :to="`/installation/${ props.dataItem.mainInstallationId }`"
              class="alt-primary-color"
              target="_blank"
            >
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </router-link>
          </td>
        </template>
        <template
          slot="childNameLink"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span v-if="!props.dataItem.isParent">{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            <router-link
              v-else
              :to="`/installation/${ props.dataItem.linkedInstallationId }`"
              class="alt-primary-color"
              target="_blank"
            >
              <span>{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
            </router-link>
          </td>
        </template>
        <template
          slot="statusTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <span :class="computedStatus(kgm_getNestedValue(props.field, props.dataItem)).color">{{ computedStatus(kgm_getNestedValue(props.field, props.dataItem)).status }}</span>
          </td>
        </template>
        <template
          slot="monitoringTemplate"
          slot-scope="{props}"
        >
          <td>
            <span :class="kgm_getNestedValue(props.field, props.dataItem)">{{ kgm_getNestedValue(props.field, props.dataItem) }}</span>
          </td>
        </template>
        <div
          slot="parentNameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="parentNameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('parentNameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="childNameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="childNameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('childNameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="statusFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="statusSelect"
            :key="`statusSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(status, key) in statusList"
              :key="`deviceSearch-statusListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, status, 'statusSelect')"
            >
              {{ status }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.statusSelect != null && ($refs.statusSelect.value != null && $refs.statusSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('statusSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="typeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="typeSelect"
            :key="`typeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, key) in installationTypes"
              :key="`deviceSearch-installationTypeFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, type, 'typeSelect')"
            >
              {{ type }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.typeSelect != null && ($refs.typeSelect.value != null && $refs.typeSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('typeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="rTypeFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="rTypeSelect"
            :key="`rTypeSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(rType, key) in rTypes"
              :key="`deviceSearch-typeFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, rType, 'rTypeSelect')"
            >
              {{ rType }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.rTypeSelect != null && ($refs.rTypeSelect.value != null && $refs.rTypeSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('rTypeSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="idFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="idFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an ID"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('idFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <div
          slot="monitoringFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="zabbixStatusSelect"
            :key="`zabbixStatusSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(type, key) in zabbixStatusList"
              :key="`deviceSearch-installationTypeFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, type, 'zabbixStatusSelect')"
            >
              {{ type }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.zabbixStatusSelect != null && ($refs.zabbixStatusSelect.value != null && $refs.zabbixStatusSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('zabbixStatusSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div
              v-if="props.dataItem.isParent"
              class="btn-group"
            >
              <button
                class="btn btn-primary btn-sm"
                @click="editLink(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="() => {
                  indexToDelete = props.dataItem.id;
                  openConfirmRemoveModal();
                }"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="trash"
                />
                <span>Remove</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        :show-sidebar="showSidebar"
        @close="hideSidebar"
      >
        <component
          :is="currentComponent"
          :installation-id="installationId"
          :link="currentLink"
          @reloadLinks="reloadLinksAndCloseSidebar"
        />
      </Sidebar>
    </template>
    <SweetModal
      ref="confirmDelete"
      icon="warning"
      blocking
      title="Delete Relation?"
      class="overflowHidden"
    >
      <p>Are you sure you want to delete this Relation?</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeLink()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { jiraStatusMixin } from '@/mixins/jiraStatusMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';
import AddInstallationLink from '@/components/Installation/AddInstallationLink.vue';
import EditInstallationLink from '@/components/Installation/EditInstallationLink.vue';
import Sidebar from '@/components/Base/Sidebar.vue';
import { SweetModal } from 'sweet-modal-vue';

export default {
  name: "InstallationRelationsList",
  components: {
    Sidebar,
    AddInstallationLink,
    EditInstallationLink,
    SweetModal
  },
  mixins: [
    kendoGridMixin,
    jiraStatusMixin,
    authenticationMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      installationRelationsList: null,
      showSidebar: false,
      currentLink: null,
      currentComponent: null, 
      indexToDelete: null,
      kgm_sort: [
        {
          "field": "name",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'parentName',
          filterable: true,
          filter: 'text',
          title: 'Parent Installtion',
          cell: 'parentNameLink',
          filterCell: "parentNameFilter"
        },
        {
          field: 'childName',
          filterable: true,
          filter: 'text',
          title: 'Child Installtion',
          cell: 'childNameLink',
          filterCell: "childNameFilter"
        },
        {
          field: 'installationType',
          filterable: true,
          filter: 'text',
          title: `Installation ${this.$t('installationList.type')}`,
          filterCell: 'typeFilter',
          width: '200px',
          hideOn: ['smDown']
        },
        {
          field: 'jiraStatus',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.status'),
          filterCell: 'statusFilter',
          cell: 'statusTemplate',
          hidden: this.authenticationHasRole('scope_customer') && this.authenticationHasRole('zerolevel')
        },
        {
          field: 'zabbixStatus',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.monitoring'),
          width: '175px',
          hideOn: ['xsDown'],
          filterCell: "monitoringFilter",
          cell: 'monitoringTemplate',
          hidden: this.authenticationHasRole('scope_customer') && this.authenticationHasRole('zerolevel')
        },
        {
          field: 'zabbixStatus',
          filterable: true,
          filter: 'text',
          title: this.$t('installationList.monitoring'),
          hideOn: this.isResort ? ['smUp', 'xsDown'] : ['smUp'],
          filterCell: "monitoringFilter",
          cell: 'monitoringTemplate',
          hidden: this.authenticationHasRole('scope_customer') && this.authenticationHasRole('zerolevel')
        },
        {
          field: 'type',
          filterable: true,
          filter: 'text',
          title: this.$t('type'),
          filterCell: 'rTypeFilter',
          hideOn: ['smDown']
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          hidden: !this.authenticationHasRole('scope_staff')
        }
      ]
    }
  },
  computed: {
    statusList () {
      if (!this.installationRelationsList) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let statusList = Array.from(new Set(this.installationRelationsList.map(o=>o.jiraStatus)));
      //Remove null values
      statusList = statusList.filter(function (e) {return e});
      return statusList.sort();
    },
    installationTypes () {
      if (!this.installationRelationsList) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let installationTypes = Array.from(new Set(this.installationRelationsList.map(o=>o.installationType)));
      //Remove null values
      installationTypes = installationTypes.filter(function (e) {return e});
      return installationTypes.sort();
    },
    rTypes () {
      if (!this.installationRelationsList) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let types = Array.from(new Set(this.installationRelationsList.map(o=>o.type)));
      //Remove null values
      types = types.filter(function (e) {return e});
      return types.sort();
    },
    zabbixStatusList: function () {
      if (!this.installationRelationsList) {
        return [ "" ];
      }
      //Distinct (include every entry only once)
      let zabbixStatusList = Array.from(new Set(this.installationRelationsList.map(o => o.zabbixStatus)));
      //Remove null values
      zabbixStatusList = zabbixStatusList.filter((e) => {
        return e;
      });
      
      return zabbixStatusList.sort();
    }
  },
  created () {
    this.getLinkedInstallations();
  },
  methods: {
    computedStatus (status) {
      if (!status) {
        return this.jiraStatus_statusToObject("unknown");
      }
      if (status.length === 0) {
        return this.jiraStatus_statusToObject("unknown");
      }
      return this.jiraStatus_statusToObject(status);
    },
    //api calls
    openConfirmRemoveModal () {
      this.$refs.confirmDelete.open();
    },
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    hideSidebar () {
      this.showSidebar = false;
      this.currentComponent = null;
    },
    addLink () {
      this.currentComponent = "AddInstallationLink";
      this.show();
    },
    editLink (emitValues) {
      this.currentComponent = "EditInstallationLink";
      this.currentLink = Object.assign({}, emitValues); 
      this.show();
    },
    //#endregion
    //#region API-calls
    removeLink () {
      this.loading = true;
      this.axios.delete(`/Installation/RemoveInstallationLink?linkId=${ this.indexToDelete }`)
        .then(() => {
          this.reloadLinksAndCloseSidebar();
          this.$snotify.success(this.$t('installationLink.deletedSuccessfully'));          
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    reloadLinksAndCloseSidebar () {
      this.hideSidebar();
      this.getLinkedInstallations();
      this.$emit("reloadAuditLogs");
    },
    getLinkedInstallations () {
      this.loading = true;
      this.$emit("startLoading", true);
      this.axios.get(`/Installation/GetInstallationLinks?installationId=${ this.installationId }`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.installationRelationsList = [];
        response.data.forEach(x => {
          var link = {
            id: x.id,
            type: x.type,
          };

          if(x.linkedInstallation && x.mainInstallation) {
            link.linkedInstallationId = x.linkedInstallation ? x.linkedInstallation.id : '';
            link.mainInstallationId = x.mainInstallation ? x.mainInstallation.id : '';
            link.childName = x.linkedInstallation.name.replace("  ", " ");
            link.parentName = x.mainInstallation.name.replace("  ", " ");
            link.isParent = x.isParent;
            if(x.isParent) {
              link.installationType = x.linkedInstallation.installationType;
              link.jiraStatus = x.linkedInstallation.jiraStatus;
              link.zabbixStatus = x.linkedInstallation.zabbixStatus;
            }
            else {
              link.installationType = x.mainInstallation.installationType;
              link.jiraStatus = x.mainInstallation.jiraStatus;
              link.zabbixStatus = x.mainInstallation.zabbixStatus;
            }
          }

          this.installationRelationsList.push(link)
        });
      })
      .finally(() => {
        this.loading = false;
        this.$emit("startLoading", false);
      });
    }
  }
}
</script>
<style>
.installationRelationsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
span.Deactivated {
  color: gray;
}

span.OK {
  color: darkgreen;
}

span.Information {
  color: #7499FF;
}

span.Warning {
  color: #FFC859;
}

span.Average {
  color: #FFA059;
}

span.High {
  color: #E97659;
}

span.Disaster {
  color: #E45959;
}
</style>