<template>
  <div class="addInstallationLink">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Installation Relation
        </h4>
        <hr class="m-0 mb-3">
        <label
          for="client"
          class="form-label"
        >Child Installation</label>
        <LoadingPlaceholder v-if="installationLoading" />
        <Multiselect
          v-else
          id="linkedInstallations"
          v-model="selectedInstallation"
          tag-placeholder="Not found"
          placeholder="Select a Installation"
          :options="installations"
          select-label=""
          :multiple="false"
          :taggable="false"
          :hide-selected="true"
          :searchable="true"
          :close-on-select="true"
          :custom-label="customLabel"
          track-by="id"
          class="mb-4"
        />
        <hr class="m-0 mb-3">
        <label>{{ $t('type') }}</label>
        <LoadingPlaceholder v-if="loading" />
        <ComboBox
          v-else
          v-model="installationLink.type"
          :data-source="linkTypes"
          :placeholder="'Select a link type...'"
          class="form-control"
        />
        <div class="m-0 mb-3 mt-3" />
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!selectedInstallation"
        @click.prevent="addInstallationLink()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
import { ComboBox } from '@progress/kendo-dropdowns-vue-wrapper';

export default {
  name: "AddInstallationLink",
  components: {
    ComboBox,
    Multiselect: () => import('vue-multiselect')
  },
  mixins: [
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      installationLink: {},
      loading: false,
      linkTypes: [],
      installationLoading: false,
      installations: null,
      selectedInstallation: null
    }
  },
  created () {
    this.getLinkTypes();
    this.getInstallations();
  },
  methods: {
    customLabel ({ name }) {
      return `${name}`
    },
    async getLinkTypes () {
      this.loading = true;
      await this.axios.get('/Installation/GetInstallationLinkTypes')
        .then((response) => {
          if (response && response.status && response.status == 200) {
            this.linkTypes = response.data.filter(x => x);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getInstallations () {
      this.installationLoading = true;
      await this.axios.get('/Installation/GetRecudedModelInstallations')
        .then((response) => {
          if (response && response.status && response.status == 200) {
            this.installations = response.data;
          }
        })
        .finally(() => {
          this.installationLoading = false;
        });
    },
    async addInstallationLink () {
      let postObject = {
        'linkedInstallationId': this.selectedInstallation.id,
        'type': this.installationLink.type,
        'mainInstallationId': this.installationId
      }
      await this.axios.post('/Installation/AddInstallationLink', postObject)
        .then(() => {
          this.$snotify.success(this.$t('installationLink.addedSuccessfully'));
          this.$emit("reloadLinks");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.addInstallationLink .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>