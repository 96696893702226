var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"installationRelationsList"},[(_vm.loading)?_c('LoadingPlaceholder'):[_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.installationRelationsList),"columns":_vm.kgm_responsiveColumns(),"filterable":true,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.installationRelationsList),"sortable":{
        allowUnsort: _vm.kgm_allowUnsort,
        mode: _vm.kgm_sortMode
      },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"parentNameLink",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.isParent)?_c('span',[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))]):_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.mainInstallationId)),"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))])])],1)]}},{key:"childNameLink",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(!props.dataItem.isParent)?_c('span',[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))]):_c('router-link',{staticClass:"alt-primary-color",attrs:{"to":("/installation/" + (props.dataItem.linkedInstallationId)),"target":"_blank"}},[_c('span',[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))])])],1)]}},{key:"statusTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{class:_vm.computedStatus(_vm.kgm_getNestedValue(props.field, props.dataItem)).color},[_vm._v(_vm._s(_vm.computedStatus(_vm.kgm_getNestedValue(props.field, props.dataItem)).status))])])]}},{key:"monitoringTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',[_c('span',{class:_vm.kgm_getNestedValue(props.field, props.dataItem)},[_vm._v(_vm._s(_vm.kgm_getNestedValue(props.field, props.dataItem)))])])]}},{key:"parentNameFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"parentNameFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a name"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('parentNameFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"childNameFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"childNameFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter a name"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('childNameFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"statusFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("statusSelect-" + (props.value)),ref:"statusSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.statusList),function(status,key){return _c('option',{key:("deviceSearch-statusListFilterOption-" + key),domProps:{"selected":_vm.kgm_selectDefaultOption(props, status, 'statusSelect')}},[_vm._v(" "+_vm._s(status)+" ")])})],2),(_vm.$refs != null && _vm.$refs.statusSelect != null && (_vm.$refs.statusSelect.value != null && _vm.$refs.statusSelect.value.length != 0))?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('statusSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"typeFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("typeSelect-" + (props.value)),ref:"typeSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.installationTypes),function(type,key){return _c('option',{key:("deviceSearch-installationTypeFilterOption-" + key),domProps:{"selected":_vm.kgm_selectDefaultOption(props, type, 'typeSelect')}},[_vm._v(" "+_vm._s(type)+" ")])})],2),(_vm.$refs != null && _vm.$refs.typeSelect != null && (_vm.$refs.typeSelect.value != null && _vm.$refs.typeSelect.value.length != 0))?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('typeSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"rTypeFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("rTypeSelect-" + (props.value)),ref:"rTypeSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.rTypes),function(rType,key){return _c('option',{key:("deviceSearch-typeFilterOption-" + key),domProps:{"selected":_vm.kgm_selectDefaultOption(props, rType, 'rTypeSelect')}},[_vm._v(" "+_vm._s(rType)+" ")])})],2),(_vm.$refs != null && _vm.$refs.rTypeSelect != null && (_vm.$refs.rTypeSelect.value != null && _vm.$refs.rTypeSelect.value.length != 0))?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('rTypeSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"idFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('input',{ref:"idFilterInput",staticClass:"form-control mr-2 rounded-right",attrs:{"type":"text","placeholder":"please enter an ID"},domProps:{"value":props.value},on:{"input":function (event) {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}}}),(props.value)?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetInput('idFilterInput', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"monitoringFilter",fn:function(ref){
      var props = ref.props;
      var methods = ref.methods;
return _c('div',{staticClass:"input-group"},[_c('select',{key:("zabbixStatusSelect-" + (props.value)),ref:"zabbixStatusSelect",staticClass:"form-control mr-2 rounded-right alt-pointer",domProps:{"value":props.value},on:{"change":function (event) {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v(" "+_vm._s(_vm.$t('pleaseSelectOne'))+" ")]),_vm._l((_vm.zabbixStatusList),function(type,key){return _c('option',{key:("deviceSearch-installationTypeFilterOption-" + key),domProps:{"selected":_vm.kgm_selectDefaultOption(props, type, 'zabbixStatusSelect')}},[_vm._v(" "+_vm._s(type)+" ")])})],2),(_vm.$refs != null && _vm.$refs.zabbixStatusSelect != null && (_vm.$refs.zabbixStatusSelect.value != null && _vm.$refs.zabbixStatusSelect.value.length != 0))?_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-light border rounded",on:{"click":function($event){_vm.kgm_resetSelect('zabbixStatusSelect', function (event) {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})}}},[_c('font-awesome-icon',{staticClass:"gray",attrs:{"icon":"times"}})],1)]):_vm._e()])}},{key:"optionsTemplate",fn:function(ref){
      var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem.isParent)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.editLink(props.dataItem)}}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"edit"}}),_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])],1),_c('button',{staticClass:"btn btn-secondary btn-sm",on:{"click":function () {
                _vm.indexToDelete = props.dataItem.id;
                _vm.openConfirmRemoveModal();
              }}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_c('span',[_vm._v("Remove")])],1)]):_vm._e()])]}}])}),_c('Sidebar',{attrs:{"show-sidebar":_vm.showSidebar},on:{"close":_vm.hideSidebar}},[_c(_vm.currentComponent,{tag:"component",attrs:{"installation-id":_vm.installationId,"link":_vm.currentLink},on:{"reloadLinks":_vm.reloadLinksAndCloseSidebar}})],1)],_c('SweetModal',{ref:"confirmDelete",staticClass:"overflowHidden",attrs:{"icon":"warning","blocking":"","title":"Delete Relation?"}},[_c('p',[_vm._v("Are you sure you want to delete this Relation?")]),_c('button',{staticClass:"btn btn-secondary float-left mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.$refs.confirmDelete.close()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2 gray",attrs:{"icon":"times"}}),_c('span',[_vm._v(_vm._s(_vm.$t('cancel')))])],1),_c('button',{staticClass:"btn btn-danger float-right mb-3",attrs:{"slot":"button"},on:{"click":function($event){return _vm.removeLink()}},slot:"button"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"trash"}}),_c('span',[_vm._v(_vm._s(_vm.$t('delete')))])],1),_c('div',{staticClass:"clearfix"})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }